<template>
  <div class="c-app">
    <TheSidebar/>
    <div class="c-wrapper">
      <TheHeader/>
      <div :class="!isMobile()?'c-body':'c-body-mobile'">
        <main class="c-main" :class="isAdvancedReport() ? 'dark-bg' : ''">
            <CContainer fluid > 
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer> 
        </main>
      </div>
      <TheFooter/>
    </div>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

import mobile from '../util/mobile';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  methods:{
    isMobile: function() {
      return mobile.isMobile();
    },
    isAdvancedReport() {
      return window.location.href.includes("AdvancedReport");
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.dark-bg {
  background-color: #0E0F0E;
}
</style>
