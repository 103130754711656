<template>
    <div>
        <img src="/img/sidebar/cover photo.gif" width="100%" height="220" @click="goToAdvancedReport"/>
    </div>
</template>

<script>
export default {
    methods: {
        goToAdvancedReport() {
            if(this.$route.path.includes("/AdvancedReport")) return;
            this.$router.push('/AdvancedReport');
        }
    }
}
</script>

<style scoped>
div > img {
    cursor: pointer;
}
</style>